import { RootState } from "store";
import { ActionTree } from "vuex";
import { TimeSheetTrackingService } from "../service";
import { FilterTimeSheetTracking, SET_LIST_TIME_SHEET_TRACKING, SET_TIME_SHEET_TRACKING_COUNT, TimeSheetHistoryParse, TimeSheetTrackingState, TimeSheetHistory, timesheetActionArea, timesheetActionType, convertValueHistory } from "./types";
import { userTypeList } from "modules/admin/users/store/types";
import { formatDate } from "../../../../utils/time";

const service = new TimeSheetTrackingService();
export const actions: ActionTree<TimeSheetTrackingState, RootState> = {
    async actLoadTimeSheetTracking({ commit }, params: any) {
        const filterTimeSheet: FilterTimeSheetTracking = this.getters["timeSheetTracking/filterTimeSheetTracking"];
        const data = await service.fetchTimeSheetTracking(filterTimeSheet, params.currentPage, params.pageSize)
        const result: TimeSheetHistoryParse[] = data.data.map((e: TimeSheetHistory) => {
            let dataChange = JSON.parse(e.changeDetail);
            let changeDetail = [];
            if ([1,3].includes(e.actionArea)) {
                changeDetail = dataChange["Detail"].map((c: any) => ({
                    field: c.Field,
                    textField: c.TextField,
                    previousValue: convertValueHistory(`Task.${c.Field}`,c.PreviousValue),
                    currentValue: convertValueHistory(`Task.${c.Field}`,c.CurrentValue),
                    previousTextValue: c.PreviousTextValue,
                    currentTextValue: c.CurrentTextValue
                }))
            } else {
                changeDetail = dataChange.map((c: any) => {
                    return {
                        field: c.Field,
                        textField: c.TextField,
                        previousValue: convertValueHistory(`Header.${c.Field}`,c.PreviousValue),
                        currentValue: convertValueHistory(`Header.${c.Field}`,c.CurrentValue),
                        previousTextValue: c.PreviousTextValue,
                        currentTextValue: c.CurrentTextValue
                    }
                })
            }
            return {
                timesheetHistoryId: e.timesheetHistoryId,
                timesheetHeaderId: e.timesheetHeaderId,
                taskId: e.actionArea == 1 ? dataChange["TimesheetLineId"] : null,
                userId: e.userId,
                actionType: timesheetActionType[e.actionType],
                actionArea: timesheetActionArea[e.actionArea],
                time: formatDate(e.time),
                changeDetail,
                user: {
                    userId: e.userId,
                    userType: userTypeList[e.user?.userType],
                    email: e.user.email,
                    name: e.user?.firstName + " " + e.user?.lastName,
                    avatarUrl: e.user?.avatarUrl,
                }
            }
        })
        commit(SET_LIST_TIME_SHEET_TRACKING, result)
        commit(SET_TIME_SHEET_TRACKING_COUNT, data.count)
    },
};