<template>
  <el-menu class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :router="false"
    :default-active="currentRoute?.toString()" textColor="#fff">
    <div class="test-env-banner" v-if="isTestMode">
        <el-row>
          <el-col align="center" class="title-banner"> {{ `${isCollapse ? 'Dev Enviroment' : 'Dev'}` }}</el-col>
        </el-row>
    </div>

    <div v-for="(sidebar) in sidebarData" :key="sidebar.url.value" class="flex justify-center items-centers">
      <el-tooltip :effect="bgColorTaskType == 'light' ? 'dark' : 'light'" :open-delay="200" :content="sidebar.title" placement="right" :disabled="isCollapse">
        <el-menu-item :index="sidebar.url.value" @click="redirectPage(sidebar.url.value)" v-show="sidebar.isShowing" :class="isCollapse ? 'w-[232px]' : ''">
          <el-icon>
            <icon-svg :iconColor="currentRoute?.toString().includes(sidebar.url.value) ? '#ED1C2D' : iconColor" width="24" height="24"
              :name="sidebar.icon" />
          </el-icon>
          <template #title v-if="isCollapse">
            <div 
              v-html="sidebar.title" 
              class="flex items-center w-[100%] justify-between sidebar-title"
              :class="!isCollapse ? 'text-[#fff] font-medium text-sm ml-1' : 'text-[#6F7485] font-semibold'"
            />
          </template>
        </el-menu-item>
      </el-tooltip>
    </div>
    
    <div class="el-menu-item-custom-isCollapse-Icon menu-dark-mode" index="dark-mode" v-if="isCollapse">
      <div class="menu-dark-mode__left">
        <icon-svg :iconColor="iconColor" width="24" height="24" name="darkmode" />
      </div>
      <div class="menu-dark-mode__right">
        <el-row class="ml-1.5 flex items-center">
          <el-col :span="16" class="title-menu">Dark Mode </el-col>
          <el-col :span="8" class="item-mandatory" align="right">
            <el-switch v-model="darkValModel" style="--el-switch-on-color: #ED1C2D; --el-switch-off-color: #3A4056"
            active-value="100" inactive-value="0" @change="toggleDark(), handleSetTheme()" />
          </el-col>
        </el-row>
      </div>
    </div>

    <el-tooltip :effect="bgColorTaskType == 'light' ? 'dark' : 'light'" :open-delay="300" :content="'Dark Mode'" placement="right" :disabled="isCollapse" v-else>
      <div class="el-menu-item-custom-isCollapse-Icon menu-dark-mode" index="dark-mode" @click="toggleDark(), handleSetTheme()">
        <div class="w-[24px] h-[24px]" :class="isCollapse ? 'pr-1' : ''">
          <icon-svg :iconColor="iconColor" width="24" height="24" name="darkmode" />
        </div>
      </div>
    </el-tooltip>
    

    <div class="flex justify-center items-centers" v-if="currentUser.userType == USER_TYPE_SYSTEM_ADMINISTRATOR">
      <el-tooltip :effect="bgColorTaskType == 'light' ? 'dark' : 'light'" :open-delay="200" :content="'Settings'" placement="right" :disabled="isCollapse">
        <el-menu-item @click="redirectPage('/admin/settings')" v-show="true" :class="isCollapse ? 'w-[232px]' : ''">
          <el-icon>
            <icon-svg :iconColor="currentRoute?.toString().includes('/admin/settings') ? '#ED1C2D' : iconColor" width="24" height="24"
              name="settings" />
          </el-icon>
          <template #title v-if="isCollapse">
            <div 
              v-html="'Settings'" 
              class="flex items-center w-[100%] justify-between sidebar-title"
              :class="!isCollapse ? 'text-[#fff] font-medium text-sm ml-1' : 'text-[#6F7485] font-semibold'"
            />
          </template>
        </el-menu-item>
      </el-tooltip>
    </div>


    <div class="el-menu-item-custom-isCollapse-Icon" @click="handleCollapse">
      <el-tooltip :effect="bgColorTaskType == 'light' ? 'dark' : 'light'" :open-delay="300" :content="'Collapse'" placement="right" :disabled="isCollapse" v-if="!isCollapse">
        <div class="ml-[6px] w-[34px]" >
          <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path 
              d="M5.33333 2H18.6667C20.5076 2 22 3.49238 22 5.33333V18.6667C22 20.5076 20.5076 22 18.6667 22H5.33333C3.49238 22 2 20.5076 2 18.6667V5.33333C2 3.49238 3.49238 2 5.33333 2ZM5.33333 4.22222C4.71968 4.22222 4.22222 4.71968 4.22222 5.33333V18.6667C4.22222 19.2803 4.71968 19.7778 5.33333 19.7778H18.6667C19.2803 19.7778 19.7778 19.2803 19.7778 18.6667V5.33333C19.7778 4.71968 19.2803 4.22222 18.6667 4.22222H5.33333Z" 
              :fill="isDark ? '#9398A0' : '#9398A0'"
            />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 3H10V21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3ZM17.3542 9.00827C17.5592 8.76545 17.5464 8.38581 17.3257 8.16033C17.1049 7.93484 16.7598 7.94891 16.5548 8.19173L13.6457 11.5917C13.4514 11.8219 13.4514 12.1781 13.6457 12.4083L16.5548 15.8083C16.7598 16.0511 17.1049 16.0652 17.3257 15.8397C17.5464 15.6142 17.5592 15.2346 17.3542 14.9917L14.7898 12L17.3542 9.00827Z" 
              :fill="isDark ? '#9398A0' : '#9398A0'"
            />
          </svg>
        </div>
      </el-tooltip>

      <div class="ml-[8px]" v-else>
        <div class="flex items-center">
          <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path 
              d="M5.33333 2H18.6667C20.5076 2 22 3.49238 22 5.33333V18.6667C22 20.5076 20.5076 22 18.6667 22H5.33333C3.49238 22 2 20.5076 2 18.6667V5.33333C2 3.49238 3.49238 2 5.33333 2ZM5.33333 4.22222C4.71968 4.22222 4.22222 4.71968 4.22222 5.33333V18.6667C4.22222 19.2803 4.71968 19.7778 5.33333 19.7778H18.6667C19.2803 19.7778 19.7778 19.2803 19.7778 18.6667V5.33333C19.7778 4.71968 19.2803 4.22222 18.6667 4.22222H5.33333Z" 
              :fill="isDark ? '#9398A0' : '#9398A0'"
            />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 3H10V21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3ZM17.3542 9.00827C17.5592 8.76545 17.5464 8.38581 17.3257 8.16033C17.1049 7.93484 16.7598 7.94891 16.5548 8.19173L13.6457 11.5917C13.4514 11.8219 13.4514 12.1781 13.6457 12.4083L16.5548 15.8083C16.7598 16.0511 17.1049 16.0652 17.3257 15.8397C17.5464 15.6142 17.5592 15.2346 17.3542 14.9917L14.7898 12L17.3542 9.00827Z" 
              :fill="isDark ? '#9398A0' : '#9398A0'"
            />
          </svg>
          <div class="title-menu flex items-center w-[100%] justify-between ml-5">Collapse</div>
        </div>
      </div>
    </div>
  </el-menu>
</template>

<script lang="ts">
// import { computed, defineComponent } from 'vue'
import { ref, watch, onBeforeMount, onMounted,watchEffect } from "vue";

import { useDark, useToggle } from "@vueuse/core";

import { Location, Document } from "@element-plus/icons-vue";
import IconSvg from "@/layouts/IconBase.vue";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { AUTH_TOKEN_KEY, USER_TYPE_APPROVER, USER_TYPE_SYSTEM_ADMINISTRATOR, USER_TYPE_USER_ADMINISTRATOR } from 'core/constants';
import { msalInstance, loginRequest } from "../../authConfig";
import { useRouter } from 'vue-router'
import { messaging } from "../../firebase/firebase";

export default defineComponent({
  name: "baseSide",
  components: {
    IconSvg,
  },
  props: {
    isCollapse: Boolean
  },
  setup(props, ctx) {
    const isTestMode = ref(false);
    watchEffect(() => {
      const currentPath = window.location.href;
      isTestMode.value = currentPath.includes('timesheetsdev.emecogroup');
    });
    const bgColorTaskType = computed(() => store.state.currentTheme);

    const router = useRouter()
    const urlDashboard = ref<string>('/dashboard')
    const currentUser = computed(() => {
      const user = store.getters['auth/currentUser']
      if (user.userType == USER_TYPE_SYSTEM_ADMINISTRATOR || user.userType == USER_TYPE_USER_ADMINISTRATOR) {
        urlDashboard.value = '/admin/dashboard'
      }
      if (user.userType == USER_TYPE_APPROVER) {
        urlDashboard.value = '/super/dashboard'
      }
      return user
    })
    const isDark = useDark();
    const store = useStore()

    const currentTheme = computed(() => isDark.value);
    const iconColor = computed(() =>
      currentTheme.value ? "#9398A0" : "#9398A0"
    );

    const darkValModel = currentTheme.value === true ? ref('100') : ref('0');
    const currentRoute = computed(() => {
      return useRouter().currentRoute.value.path;
    })

    const handleOpen = (key: string, keyPath: string[]) => {
    };
    const handleClose = (key: string, keyPath: string[]) => {
    };
    const handleLogout = () => {
      localStorage.setItem(AUTH_TOKEN_KEY, '');
      msalInstance.logoutRedirect()
    }
    const redirectPage = (path: string) => {
      router.push(path);
      store.dispatch("dashboard/actGetTimeSheetEvent")
    }

    const toggleDark = useToggle(isDark)

    const handleSetTheme = () => {
      store.commit('SET_THEME', !isDark.value ? 'light' : 'dark');
    }

    watch(currentTheme, () => {
      // store.commit('SET_THEME', currentTheme.value ? 'dark':'light');
      darkValModel.value = currentTheme.value ? '100' : '0';
    })

    onMounted(() => {
      messaging.onMessage((payload) => {
        console.log(payload)
        store.dispatch("dashboard/actGetTimeSheetEvent")
      });
      store.dispatch("dashboard/actGetTimeSheetEvent")
    });
    const countNotiUnread = computed(() => store.getters["dashboard/countNotiUnRead"]);
    const notifications = computed(() => store.getters["dashboard/timeSheetEvents"]);
    const sidebarData = computed(() => [
      {
        icon: "home",
        url: urlDashboard,
        isShowing: true,
        title: "Timesheet",
      },
      {
        icon: "manuals",
        url: {
          value: "/manuals"
        },
        isShowing: true,
        title: "Manuals",
      },
      {
        icon: "profiles",
        url: {
          value: "/profile"
        },
        isShowing: true,
        title: "Profile"
      },
      {
        icon: "double-people",
        url: {
          value: "/admin/users"
        },
        isShowing: currentUser.value.userType == USER_TYPE_SYSTEM_ADMINISTRATOR ||  currentUser.value.userType == USER_TYPE_USER_ADMINISTRATOR,
        title: "Users",
      },
      {
        icon: "users",
        url: {
          value: "/admin/approval-groups"
        },
        isShowing: currentUser.value.userType == USER_TYPE_SYSTEM_ADMINISTRATOR ||  currentUser.value.userType == USER_TYPE_USER_ADMINISTRATOR,
        title: "Approval Group",
      },
      {
        icon: "timesheet-tracking",
        url: {
          value: "/admin/timesheet-trackings"
        },
        isShowing: currentUser.value.userType == USER_TYPE_SYSTEM_ADMINISTRATOR || currentUser.value.userType == USER_TYPE_USER_ADMINISTRATOR,
        title: "Timesheet Tracking",
      },
      {
        icon: "user-tracking",
        url: {
          value: "/admin/user-trackings"
        },
        isShowing: currentUser.value.userType == USER_TYPE_SYSTEM_ADMINISTRATOR,
        title: "User Tracking",
      },
      {
        icon: "notifications",
        url: {
          value: "/notifications"
        },
        isShowing: currentUser.value.userType != USER_TYPE_USER_ADMINISTRATOR,
        title: `${props.isCollapse ? `<div >Notifications</div> ${countNotiUnread?.value ? `<div class="count-noti">${countNotiUnread?.value}</div>` : ''}`: 'Notifications'}`
      },
    ])

    const handleCollapse = () => {
      ctx.emit('handleCollapse')
    }

    return {
      currentUser,
      darkValModel,
      currentRoute,
      handleOpen,
      handleClose,
      toggleDark,
      urlDashboard,
      handleLogout,
      USER_TYPE_SYSTEM_ADMINISTRATOR,
      redirectPage,
      iconColor,
      sidebarData,
      notifications,
      countNotiUnread,
      handleCollapse,
      isDark,
      isTestMode,
      bgColorTaskType,
      handleSetTheme,
    };
  },
});
</script>

<style lang="scss">
.count-noti {
  border-radius: 50%;
  background: #EA3943;
  color: #fff;
  padding: 2px 4px;
  font-size: 12px;
  font-weight: 500;
  max-height: 23px;
  min-width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-menu {
  font-weight: 600;
  font-size: 16px;
  color: #6F7485;
}
.test-env-banner{
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../../assets/test-env-bg.svg');
  height:56px;
  margin-bottom: 12px;
  margin-top:-12px
}
.title-banner{
  color:#fff;
  font-size: 22px;
  font-weight: 600;
}

.el-menu-item.el-tooltip__trigger {
  padding: 16px !important;
  padding-left: 14px !important;
}
.el-menu-item.el-menu-item-custom-1 {
  padding-left: 16px !important;
}

.el-menu-item.is-active {
  .sidebar-title {
    color: #231F20 !important;
  }
}

.dark {
  .el-menu-item.is-active {
    .sidebar-title {
      color: #fff !important;
    }
  }
}

.menu-dark-mode {
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 14px !important;
  .menu-dark-mode__left {
    flex: 1;
  }
  .menu-dark-mode__right {
    flex: 5;
  }
}

.el-menu-item .el-icon {
  font-size: 24px;
}
</style>