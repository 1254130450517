import store from "store";
import { computed, nextTick } from "@vue/runtime-core";
import {
  createRouter,
  createWebHistory,
  Router,
  RouteLocationNormalized,
} from "vue-router";
import { msalInstance, loginRequest, tokenRequest } from "../authConfig";
import { InteractionType } from "@azure/msal-browser";
import routes from "./routes";
import { isAuthenticated, checkTokenExpire, clearCookie, autoRefreshToken, getCookie, clearAllCookies } from "../utils";
import {
  AUTH_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  USER_TYPE_APPROVER,
  USER_TYPE_SYSTEM_ADMINISTRATOR,
  USER_TYPE_USER_ADMINISTRATOR,
} from "core/constants";
const router: Router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to: any, from: RouteLocationNormalized) => {
  if (to.meta.requiresAuth) {
    try {
      const token = localStorage.getItem(AUTH_TOKEN_KEY) || sessionStorage.getItem(AUTH_TOKEN_KEY);
      const isTokenExpire = checkTokenExpire(token);
      // const request = {
      //   ...loginRequest,
      //   redirectStartPage: to.fullPath,
      // };
      let currentUser = store.getters["auth/currentUser"];


      if (currentUser.userId === undefined || isTokenExpire) {
        currentUser = await store.dispatch("auth/actGetMe");
      }
      if (currentUser) {
        const isDashBoard = to.matched.some(
          (p: any) => p.path.indexOf("/dashboard") === 0
        );
        if (isDashBoard) {
          if (currentUser.userType === USER_TYPE_APPROVER) {
            return "/super/dashboard";
          }
          if (currentUser.userType === USER_TYPE_SYSTEM_ADMINISTRATOR) {
            return "/admin/dashboard";
          }
          if (currentUser.userType === USER_TYPE_USER_ADMINISTRATOR) {
            return '/admin/dashboard'
          }
        }
        // Check permission
        if (to.meta.roles !== undefined && to.meta.roles !== null) {
          if (!to.meta.roles.includes(currentUser.userType)) {
            return '/access-denied'
          }
        }
      }
    } catch (e: any) {
      localStorage.setItem(AUTH_TOKEN_KEY, "");
      sessionStorage.setItem(AUTH_TOKEN_KEY, "")
      // msalInstance.logoutRedirect()
    }
  }

  // let currentUser = store.getters["auth/currentUser"];
  // if (currentUser.userId === undefined) {
  //   currentUser =  await store.dispatch("auth/actGetMe");
  //   if (!currentUser){
  //     localStorage.setItem(AUTH_TOKEN_KEY, '');
  //     msalInstance.logoutRedirect()
  //   }
  // }
  return true;
});

export default router;
