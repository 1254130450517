import Service from "core/service";
import type { AxiosError } from 'axios';
export class ProfileService extends Service {
  dropDownSite(selectProps?: string, filter?: string, apply?: string, limit?: number, active?: number): Promise<any> {
    let params: any = { "$filter": active == -1 ? '' : active == 1 || active == undefined ? "(Active eq true)" : "(Active eq false)" };
    if (selectProps) {
      params['$select'] = selectProps
    }
    if (filter && apply) {
      params["$apply"] = `filter(${filter})/${apply}`;
    } else {
      if (filter) {
        params["$filter"] += params["$filter"].length > 0 ? ` and (${filter})` : `${filter}`;
      }
      if (apply) {
        params["$apply"] = apply;
      }
    }
    if (limit) {
      params["$top"] = limit;
    }

    return this.get("/Site", params);
  }

  dropDownEquipment(selectProps?: string, filter?: string, apply?: string, limit?: number, active?: number): Promise<any> {
    let params: any = { "$filter": active == -1 ? '' : active == 1 || active == undefined ? "(Active eq true)" : "(Active eq false)" };
    if (selectProps) {
      params['$select'] = selectProps
    }
    if (filter && apply) {
      params["$apply"] = `filter(${filter})/${apply}`;
    } else {
      if (filter) {
        params["$filter"] += params["$filter"].length > 0 ? ` and ${filter}` : `${filter}`;
      }
      if (apply) {
        params["$apply"] = apply;
      }
    }
    if (limit) {
      params["$top"] = limit;
    }
    return this.get("/Equipment", params);
  }

  getEquipmentById(id: number, expand?: string): Promise<any> {
    let params: any = {};
    if (expand) {
      params['$expand'] = expand
    }
    return this.get(`/Equipment(${id})`, params);
  }

  dropDownJob(selectProps: string, filter: string | null, limit?: number, active?: number): Promise<any> {
    let params: any = {
      $select: selectProps,
      "$filter": active == -1 ? '' : active == 1 || active == undefined ? "(Active eq true)" : "(Active eq false)"
    };
    if (filter) {
      params["$filter"] += params["$filter"].length > 0 ? ` and (${filter})` : `${filter}`;
    }
    if (limit) {
      params["$top"] = limit;
    }
    return this.get("/Job", params);
  }

  dropDownJobCost(selectProps: string, filter: string | null, limit?: number, active?: number): Promise<any> {
    let params: any = {
      $select: selectProps,
      "$filter": active == -1 ? '' : active == 1 || active == undefined ? "(Active eq true)" : "(Active eq false)"
    };
    if (filter) {
      params["$filter"] += params["$filter"].length > 0 ? ` and (${filter})` : `${filter}`;
    }
    if (limit) {
      params["$top"] = limit;
    }
    return this.get("/JobCostCode", params);
  }

  dropDownWorkOrder(selectProps: string, filter: string | null, expand?: string | null, limit?: number, active?: number): Promise<any> {
    let params: any = {
      $select: selectProps,
      "$filter": active == -1 ? '' : active == 1 || active == undefined ? "(Active eq true)" : "(Active eq false)"
    };
    if (filter) {
      params["$filter"] += params["$filter"].length > 0 ? ` and (${filter})` : `${filter}`;
    }
    if (expand) {
      params['$expand'] = expand
    }

    params["$top"] = limit || 200;

    return this.get("/WorkOrder", params);
  }

  getWorkOrderById(id: number, expand?: string): Promise<any> {
    let params: any = {

    };
    if (expand) {
      params['$expand'] = expand
    }
    return this.get(`/WorkOrder(${id})`, params);
  }

  dropDownWorkOrderTask(selectProps: string, filter: string | null, limit?: number, active?: number): Promise<any> {
    let params: any = {
      $select: selectProps,
      "$filter": active == -1 ? '' : active == 1 || active == undefined ? "(Active eq true)" : "(Active eq false)"
    };
    if (filter) {
      params["$filter"] += params["$filter"].length > 0 ? ` and (${filter})` : `${filter}`;
    }
    if (limit) {
      params["$top"] = limit;
    }
    return this.get("/WorkOrderTask", params);
  }

  dropDownGLAccount(selectProps: string, filter: string | null, active?: number): Promise<any> {
    let params: any = {
      $select: selectProps,
      "$filter": active == -1 ? '' : active == 1 || active == undefined ? "(Active eq true)" : "(Active eq false)"
    };
    if (filter) {
      params["$filter"] += params["$filter"].length > 0 ? ` and (${filter})` : `${filter}`;
    }
    return this.get("/GLAccount", params);
  }

  getWorkOrderTaskById(id: number, expand?: string): Promise<any> {
    let params: any = {

    };
    if (expand) {
      params['$expand'] = expand
    }
    return this.get(`/WorkOrderTask(${id})`, params);
  }

  getJobCostCodeById(id: number, expand?: string): Promise<any> {
    let params: any = {

    };
    if (expand) {
      params['$expand'] = expand
    }
    return this.get(`/JobCostCode(${id})`, params);
  }

  getGlAccountById(id: number): Promise<any> {
    return this.get(`/GLAccount(${id})`);
  }

  fetchTimeSheet(expand?: string, filter?: string) {
    let params: any = {
      // '$orderby': 'CreatedOn desc'
    };
    if (expand) {
      params["$expand"] = expand;
    }
    if (filter) {
      params["$filter"] = filter;
    }
    return this.get("/Timesheet", params);
  }

  getTopApprover(expand?: string, filter?: string) {
    let params: any = {
      // '$orderby': 'CreatedOn desc'
    };
    if (expand) {
      params["$expand"] = expand;
    }
    if (filter) {
      params["$filter"] = filter;
    }
    return this.get("/Timesheet", params);
  }

  async updateAvatar(formData: any): Promise<any> {
    try {
      return this.postFormData("/User/Avatar", formData);

    } catch (error) {
      throw (error as AxiosError).response;
    }
  }

  async selectWorkingHour(param: any) {
    try {
      let params: any = {
      };

      if (param) {
        params["summary"] = param.sortBy; //Month or Year
      }
      const res = this.get("/Profile/WorkingHours", params);
      return res;

    } catch (error) {
      throw (error as AxiosError).response;
    }
  }

  async selectTopApprover(param: any) {
    try {
      let params: any = {
      };

      if (param) {
        params["summary"] = param.sortBy; //Month or Year
      }
      const res = this.get("/Profile/TopApprovers", params);
      return res;

    } catch (error) {
      throw (error as AxiosError).response;
    }
  }

  patchForceLogout() {
    try {
      return this.patch("/Profile/ForceLogout");
    } catch (error) {
      throw (error as AxiosError).response;
    }
  }

  dropDownTaskCategory(filter?: string): Promise<any> {
    let params: any = {};

    if (filter) {
      params["$filter"] = filter;
    }
    return this.get("/TaskCategory", params);
  }

}
