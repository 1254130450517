import { convertStatusTimeSheet, convertTaskIdToType } from "../../../../utils"
import {getHoursAndMinutes} from "../../../../utils/time"

export interface FilterTimeSheetTracking {
    UserId?: string,
    Search?: string,
    Email?: string,
    SearchByField?: string,
    TimesheetHeaderId?: number,
    ActionArea?: number,
    ActionType?: number,
    Status?: number,
    FromDate?: number,
    ToDate?: number,
    _Top?: number,
    _Skip?: number,
    _OrderBy?: string,
    _OrderType?: string,
}

export interface TimeSheetHistory {
    timesheetHistoryId: number
    timesheetHeaderId: number
    userId: string
    actionType: number
    actionArea: number
    time: string
    changeDetail: string
    user: {
        userId: string
        userType: number
        firstName?: string
        email?: string
        lastName?: string
        avatarUrl?: string
    }
}

export interface TimeSheetHistoryParse {
    timesheetHistoryId: number
    timesheetHeaderId: number
    taskId?: number
    userId: string
    actionType: string
    actionArea: string
    time: string
    changeDetail: {
        field: string
        previousValue?: string | number | null
        currentValue?: string | number | null
        previousTextValue?: string | null
        currentTextValue?: string | null
    }[]
    user: {
        userId: string
        userType: number
        avatarUrl?: string
        name?: string
        email?: string
    }
}

export interface TimeSheetTrackingState {
    timeSheetTrackings: TimeSheetHistory[],
    filterTimeSheetTracking: FilterTimeSheetTracking,
    timeSheetTrackingCount: number,
    loading: boolean
}

export const SET_LIST_TIME_SHEET_TRACKING = 'SET_LIST_TIME_SHEET_TRACKING'
export const SET_FILTER_TIME_SHEET_TRACKING = 'SET_FILTER_TIME_SHEET_TRACKING'
export const SET_TIME_SHEET_TRACKING_COUNT = 'SET_TIME_SHEET_TRACKING_COUNT'
export const SET_LOADING_TIME_SHEET_TRACKING = 'SET_LOADING_TIME_SHEET_TRACKING'

export const timesheetActionArea = Object.freeze({
    0: 'Header',
    1: 'Task',
    2: 'Status changed',
    3: 'Field Service'

})

export const timesheetActionType = Object.freeze({
    0: 'Create',
    1: 'Edit',
    2: 'Delete'
})

export const convertValueHistory = (field: string, value: string): string => {
    switch (field) {
        case "Header.TimesheetStatus":
            return value != null ? convertStatusTimeSheet(+value) : value
        case "Task.TimesheetLineType":
            return value != null ? convertTaskIdToType(+value) : value
        case "Task.StartTime":
        case "Task.EndTime":
            if(value == null || value === '')
                return value
            const hoursAndMinutes = getHoursAndMinutes(+value)
            const hours = hoursAndMinutes.hours<10?"0"+hoursAndMinutes.hours:hoursAndMinutes.hours
            const minutes = hoursAndMinutes.minutes<10?"0"+hoursAndMinutes.minutes:hoursAndMinutes.minutes
            let formatHours
            if (hours == 0) {
                formatHours = 12
            } else {
                formatHours = hours > 12 ?  hours as number % 12 : hours  
            }
            return `${formatHours}:${minutes} ${hoursAndMinutes.isAm? "am" : "pm"}`
        default:
            return value
    }
}